type SetupClarityProps = {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
};

// eslint-disable-next-line import/prefer-default-export
export async function setupClarity({ id, email, firstName, lastName }: SetupClarityProps = {}) {
  if (window.Cypress || !window.initializeClarity) {
    return;
  }
  if (!window.clarity) {
    await window.initializeClarity();
  }

  const name = firstName && lastName ? `${firstName} ${lastName}` : firstName ?? lastName;

  if (typeof email === "string") {
    window.clarity?.("set", "email", email);
  }

  if (typeof id === "string" && typeof name === "string") {
    window.clarity?.("identify", id, "", "", name);
    return;
  }

  if (typeof id === "string") {
    window.clarity?.("identify", id);
  }
}
